import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import moment from "moment"
import marked from "marked"
import map from "../images/icons/map-marker.png"
import work from "../images/icons/work.png"
import date from "../images/icons/date-range.png"
import puzzle from "../images/icons/puzzle.png"
import search from "../images/icons/search.png"
import peer from "../images/icons/peer.png"
import SendApplication from "../components/job/send-application"
import loading from "../images/loading.gif"

const JobTemplate = ({ data, pageContext }) => {
  const [showSendApplicationModal, setShowSendApplicationModal] = useState(
    false
  )
  const [load, setLoad] = useState(true)
  const job = data.allSitePage.edges[0].node.context

  const jobTitle = job.title.split(" ")

  // console.log("desc", job.publicDescription)
  const { id } = pageContext

  function createDesc(text) {
    return text
      .replace(/(<([^>]+)>)/gi, "")
      .replaceAll("&nbsp;", " ")
      .replace(/^\s+|\s+$/g, "")
      .split("\n")
      .join("")
  }

  useEffect(() => {
    setTimeout(() => {
      if (window) {
        let option = document.querySelectorAll(".job-board p")
        for (let ctr = 0; ctr < option.length; ctr++) {
          if (option[ctr].innerHTML === "&nbsp;") {
            option[ctr].remove()
          }
        }
      }
    }, 3000)
  }, [])

  useEffect(() => {
    const addDataStructure = () => {
      const jobDesc = createDesc(job.publicDescription)
      setTimeout(() => {
        setLoad(false)
      }, 2000)
      const script = document.createElement("script")
      script.id = "data-structured"
      script.type = "application/ld+json"
      script.text = `
         {
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "${job.title}",
          "description" : "${jobDesc}",
          "datePosted" : "${moment(job.dateLastPublished).format(
            "YYYY-MM-DD"
          )}",
          "validThrough": "${moment(job.dateLastPublished).format(
            "YYYY-MM-DD"
          )}",
          "applicantLocationRequirements": {
            "@type": "Country",
            "name": "${job.address.state}"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "${job.address.city}",
              "addressCountry": "${job.address.state}"
            }
           },
          "jobLocationType": "TELECOMMUTE",
          "employmentType": "${job.employmentType}",
          "hiringOrganization" : {
            "@type" : "Organization",
            "name" : "Peer Source",
            "sameAs" : "https://www.mypeersource.com",
            "logo" : "https://www.mypeersource.com/static/mainLogo-f6e178046a7197f67a55ddd0b853c504.png"
          },
         "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "USD",
            "value": {
              "@type": "QuantitativeValue",
              "value": ${job.salary},
              "unitText": "${job.salaryUnit}"
            }
          }
        }
      `
      document.getElementsByTagName("head")[0].appendChild(script)
    }
    addDataStructure()
    return () => {
      const scripTag = document.getElementById("data-structured")
      if (scripTag !== undefined) scripTag.remove()
    }
  }, [])

  return (
    <Layout>
      <SEO title={job.title} />
      <div className="page-headline">
        <Container>
          <div className="section-heading text-center">
            <h1 className="font-weight-bold text-uppercase flair">
              {jobTitle.map(item => (
                <span>{item}</span>
              ))}
            </h1>
          </div>
        </Container>
      </div>

      <div className="page-content pt-4 pb-0">
        <Container className="pb-100">
          {!load ? (
            <Col md={10} className="m-auto">
              <div className="job-board">
                <div className="job-info">
                  <Row>
                    <Col md={4} sm={4} xs={4} className="text-sm-center">
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={map} alt="map" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Location
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {job?.address?.city}, {job?.address?.state}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={4}
                      sm={4}
                      xs={4}
                      className="border-x-grey text-sm-center"
                    >
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={work} alt="work" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Employment Type
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {job?.employmentType}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} sm={4} xs={4} className="text-sm-center">
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={date} alt="date" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Date Posted
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {" "}
                            {moment(job.dateLastPublished).format(
                              "MMM DD, YYYY"
                            )}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {job.publicDescription && (
                  <>
                    <div
                      className="job-summary text-left"
                      dangerouslySetInnerHTML={{
                        __html: job.publicDescription,
                      }}
                    />

                    <div className="text-center pb-5">
                      <button
                        className="btn common-btn"
                        onClick={() => {
                          setShowSendApplicationModal(true)
                        }}
                      >
                        Apply Now
                      </button>
                      <SendApplication
                        jobId={id}
                        show={showSendApplicationModal}
                        setShow={setShowSendApplicationModal}
                      ></SendApplication>
                    </div>
                  </>
                )}
              </div>
            </Col>
          ) : (
            <div className="text-center">
              <img src={loading} className="img-fluid" alt="loading" />
            </div>
          )}
        </Container>
        <div className="bg-blue text-center py-100">
          <Container>
            <Row>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={puzzle} alt="puzzle" />
                  <h4>Looking for IT Talent?</h4>
                  <p>
                    Our PeerNetwork model and experienced recruiters allows us
                    to offer a wide range of technical and non-technical talent
                    for our clients.
                  </p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={search} alt="search" />
                  <h4>Search for Jobs</h4>
                  <p>Check out our current opportunities!</p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={peer} alt="peer" />
                  <h4>PeerNetwork</h4>
                  <p>
                    Find out more about our unique PeerNetwork of Companies that
                    enables us to provide the best possible service to our
                    candidates and clients.
                  </p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default JobTemplate

export const jobQuery = graphql`
  query JobPostTemplateQuery($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            title
            salaryUnit
            salary
            employmentType
            dateLastPublished
            _score
            isOpen
            isPublic
            publicDescription
            publishedCategory {
              id
              name
            }
            address {
              city
              state
            }
          }
          path
        }
      }
    }
  }
`
